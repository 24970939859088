import { useRef } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useGLTF, useTexture, Decal, Environment, Center, AccumulativeShadows, RandomizedLight } from '@react-three/drei'
import { easing } from 'maath'
import { useSnapshot } from 'valtio'
import { state } from './store'
import * as THREE from 'three'

export const App = ({ position = [0, 0, 2.5], fov = 25 }) => (
  <>
    <Canvas shadows camera={{ position, fov }} gl={{ preserveDrawingBuffer: true }} eventSource={document.getElementById('root')} eventPrefix="client">
      <ambientLight intensity={0.5} />
      <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/potsdamer_platz_1k.hdr" />
      <CameraRig>
        <Backdrop />
        <Center>
          <Shirt />
        </Center>
      </CameraRig>
    </Canvas>
  </>
)

function Backdrop() {
  const shadows = useRef()
  useFrame((state, delta) => easing.dampC(shadows.current.getMesh().material.color, state.color, 0.25, delta))
  return (
    <AccumulativeShadows ref={shadows} temporal frames={60} alphaTest={0.85} scale={10} rotation={[Math.PI / 2, 0, 0]} position={[0, 0, -0.14]}>
      <RandomizedLight amount={4} radius={9} intensity={0.55} ambient={0.25} position={[5, 5, -10]} />
      <RandomizedLight amount={4} radius={5} intensity={0.25} ambient={0.55} position={[-5, 5, -9]} />
    </AccumulativeShadows>
  )
}

function CameraRig({ children }) {
  const group = useRef()
  const snap = useSnapshot(state)
  useFrame((state, delta) => {
    easing.damp3(state.camera.position, [snap.intro ? -state.viewport.width / 4 : 0, 0, 2], 0.25, delta)
  })
  return <group ref={group}>{children}</group>
}

function Shirt(props) {
  const snap = useSnapshot(state)
  const texture = useTexture(`/${snap.decal}.png`)
  const { nodes, materials } = useGLTF('/shirt_baked_collapsed.glb')
  const group = useRef()
  const { gl, scene, camera } = useThree()
  const raycaster = new THREE.Raycaster()
  const mouse = new THREE.Vector2()

  const handlePointerDown = (event) => {
    state.isEditingDecal = true
  }

  const handlePointerUp = (event) => {
    state.isEditingDecal = false
  }

  const handlePointerMove = (event) => {
    if (!state.isEditingDecal) return

    // Chuyển đổi tọa độ chuột sang tọa độ chuẩn hóa thiết bị (NDC)
    mouse.x = (event.clientX / gl.domElement.clientWidth) * 2 - 1
    mouse.y = -(event.clientY / gl.domElement.clientHeight) * 2 + 1

    // Sử dụng raycaster để tìm vị trí giao nhau với object
    raycaster.setFromCamera(mouse, camera)
    const intersects = raycaster.intersectObject(nodes.T_Shirt_male)

    if (intersects.length > 0) {
      const intersect = intersects[0]
      const newPosition = intersect.point.toArray()
      state.decalPosition = adjustDecalPosition(newPosition, nodes.T_Shirt_male.geometry.boundingBox)
    }
  }

  const adjustDecalPosition = (position, boundingBox) => {
    const [x, y, z] = position
    const { min, max } = boundingBox

    return [
      Math.max(min.x, Math.min(max.x, x)),
      Math.max(min.y, Math.min(max.y, y)),
      Math.max(min.z, Math.min(max.z, z))
    ]
  }

  useFrame((state, delta) => {
    easing.dampC(materials.lambert1.color, snap.color, 0.25, delta)
    easing.dampE(group.current.rotation, [0, snap.rotation[1], 0], 0.25, delta)
  })

  return (
    <group ref={group} {...props}>
      <mesh
        castShadow
        geometry={nodes.T_Shirt_male.geometry}
        material={materials.lambert1}
        material-roughness={1}
        dispose={null}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onPointerMove={handlePointerMove}
      >
        <Decal
          position={snap.decalPosition}
          rotation={[0, 0, 0]}
          scale={0.15}
          map={useTexture(`/${snap.decal}.png`)}
          map-anisotropy={16}
        />
      </mesh>
    </group>
  )
}

useGLTF.preload('/shirt_baked_collapsed.glb')
;['/react.png', '/three2.png', '/pmndrs.png'].forEach(useTexture.preload)