// src/utils.js
import { state } from './store'

let rotationInterval = null

export function startRotation(direction) {
  if (rotationInterval) return
  rotationInterval = setInterval(() => {
    if (direction === 'left') {
      state.rotation[1] -= 0.05
    } else if (direction === 'right') {
      state.rotation[1] += 0.05
    }
  }, 16) // roughly 60fps
}

export function stopRotation() {
  clearInterval(rotationInterval)
  rotationInterval = null
}
